export enum Role {
    CampaignManager = "Campaign Manager",
    CompanyCampaignManager = "Company Campaign Manager",
    CompanyOwner = "Company Owner",
}

export interface RoleInfo {
    [Role.CampaignManager]: undefined;
    [Role.CompanyCampaignManager]: {
        companyId: string;
    };
    [Role.CompanyOwner]: {
        companyId: string;
    };
}

export interface AuthRole<R extends Role> {
    role: R;
    info: RoleInfo[R];
}

export const isRole = <R extends Role>(
    authRole: AuthRole<Role>,
    role: R,
): authRole is AuthRole<R> => authRole.role === role;

export const findRole = <R extends Role>(
    authRoles: AuthRole<Role>[],
    role: R,
): AuthRole<R> | undefined => {
    return authRoles.find(authRole => isRole(authRole, role)) as
        | AuthRole<R>
        | undefined;
};

export const isCompanyRole = (
    authRole: AuthRole<Role>,
): authRole is
    | AuthRole<Role.CompanyOwner>
    | AuthRole<Role.CompanyCampaignManager> =>
    isRole(authRole, Role.CompanyOwner) ||
    isRole(authRole, Role.CompanyCampaignManager);
